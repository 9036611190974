.farmsPanel .ant-card-body {
  padding-right: 0px;
}

.farmsContainer {
  min-height: 195px;
  margin-top: 40px;
  max-width: 281px;
  border: 2px dashed RGBA(206, 207, 217, 0.5);
  border-radius: 16px;
}

.farmsContainer h1 {
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
}

.farmsContainer .info {
  margin-top: 20px;
  margin-bottom: 5px;
  margin-left: 25px;
}

.farmsContainer .info .goriant {
  font-size: 24px;
  font-weight: bold;
  margin-right: 5px;
}

.farmsPanel button {
  position: absolute;
  bottom: 0;
  background: linear-gradient(to bottom right, #00cfde, #05a660) border-box;
  padding: 15px 25px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 0.5em;
  cursor: pointer;
  border-style: unset;
  box-sizing: unset;
  cursor: pointer;
}

.farmsPanel button:disabled {
  background: #555770;
  color: #8F90A6;
}
.compounding-container .logo-container {
  font-weight: 700;
  font-size: 20px;
  position: relative;
  margin-top: 3%;
}

.compounding-container .goriant .logo-container span {
  position: absolute;
  top: 1%;
  left: 40%;
}

.compounding-container .ray .logo-container span {
  position: absolute;
  top: 1%;
  left: 45%;
}

.compounding-container .apy-container {
  text-align: center;
}

.compounding-container .apy-container .percent {
  font-size: 32px;
  font-weight: bold;
}

.compounding-container .apy-container .text {
  font-size: 20px;
  font-weight: 600;
}

.compounding-container .total-container {
  text-align: center;
  position: relative;
}

.compounding-container .total-container .text {
  font-size: 14px;
  font-weight: bold;
}

.compounding-container .total-container .number {
  font-size: 20px;
  font-weight: 600;
  position: absolute;
  width: 100%;
  bottom: 0;
  text-align: center;
}

.compounding-info .staked-container {
  text-align: center;
  position: relative;
}

.compounding-info .staked-container .text {
  font-size: 14px;
  font-weight: bold;
}

.compounding-info .number {
  font-size: 20px;
  font-weight: 600;
  position: absolute;
  width: 100%;
  bottom: 0;
  text-align: center;
}

.compounding-container .detail-button {
  position: relative;
  text-align: center;
}

.compounding-container .detail-button a {
  color: #06c270;
  position: absolute;
  top: 35%;
  cursor: pointer;
}
.compounding-container .detail-button .button {
  position: absolute;
  background: linear-gradient(to bottom right, #00cfde, #05a660) border-box;
  padding: 8px 25px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 0.5em;
  cursor: pointer;
  top: 35%;
}

.compounding-container .detail-button .connect {
  position: absolute;
  background: linear-gradient(#1c1c28, #1c1c28) padding-box, linear-gradient(to right, #00cfde, #05a660) border-box;
  padding: 8px 25px;
  font-size: 12px;
  border: 2px solid transparent;
  font-weight: bold;
  border-radius: 0.8em;
  cursor: pointer;
  top: 35%;
}

.compounding-container .detail-button .connect:hover {
  background: none;
  background-color: #05a660;
  -webkit-transition: background-color 0.5s linear;
  -ms-transition: background-color 0.5s linear;
  transition: background-color 0.5s linear;
}

.compounding-container .detail-button .stroke-up {
  display: inline-block;
  border: 1px solid #06c270;
  transform: rotate(-90deg);
  width: 3.32px;
  height: 8px;
}


.feature-container {
  margin-top: 40px;
}

.feature-container .staked-container {
  text-align: left;
  border: 2px dashed RGBA(206, 207, 217, 0.5);
  border-radius: 16px;
  min-height: 123px;
  min-width: 164px;
  padding-left: 2%;
  padding-top: 1%;
}

.feature-container .staked-container .text {
  font-weight: 600;
  font-size: 13px;
}

.feature-container .staked-container .number {
  font-weight: 500;
  font-size: 28px;
  margin-top: 12%;
}



.withdraw-deposit-container {
  border: 2px dashed RGBA(206, 207, 217, 0.5);
  border-radius: 16px;
  min-width: 526px;
  margin-left: 15px;
  padding-left: 2%;
  padding-top: 1.2%;
}
.withdraw-deposit-container .dash {
  margin-right: 10px;
  margin-left: 10px;
  font-size: 10px;
  font-weight: 500;
}
.withdraw-deposit-container span.text {
  font-weight: 600;
  font-size: 13px;
}

.withdraw-deposit-container label.text {
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
  margin-right: 10px;
  display: inline-block;
}
.withdraw-deposit-container label.text input[type='radio'] {
  visibility: hidden;
  height: 0;
  width: 0;
  opacity: 0;
}

.withdraw-deposit-container .stake-amount .number-container {
  width: 100%;
  background: #26273b;
  border: 1px solid #555770;
  box-sizing: border-box;
  box-shadow: inset 0px 0.5px 4px rgba(0, 0, 0, 0.32);
  border-radius: 8px;
  margin-top: 10px;
}

.withdraw-deposit-container .stake-amount .number-container .number {
  text-align: right;
  padding-right: 10px;
  font-weight: 500;
  font-size: 22px;
  font-weight: 500;
  border: unset;
  background: #26283b;
}

.withdraw-deposit-container .stake-amount .number-container .number input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.withdraw-deposit-container .stake-amount .number-container .number input:focus-visible
  {
    outline: 0 !important;
    box-shadow: none !important;
}
.withdraw-deposit-container .stake-amount .number-container .text {
  font-weight: 500;
  font-size: 16px;
  position: relative;
}

.withdraw-deposit-container .stake-amount .number-container .text span {
  position: absolute;
  top: 20%;
}

.withdraw-deposit-container .stake-amount .number-container .max-button {
  text-align: right;
  position: relative;
}

.withdraw-deposit-container .stake-amount .number-container .max-button button {
  background: #555770;
  font-weight: 500;
  font-size: 10px;
  text-align: center;
  padding-top: 6px;
  padding-bottom: 3px;
  padding-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
  border-radius: 2em;
  top: 20%;
  right: 0;
  cursor: pointer;
  border-style: unset;
  box-sizing: unset;
  cursor: pointer;
}

.withdraw-deposit-container .stake-amount .deposit-button-container {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
}

.withdraw-deposit-container .stake-amount .deposit-button-container button {
  background-color: #05a660;
  border-radius: 0.5em;
  text-transform: capitalize;
  padding: 10px 10px;
  margin-top: 10px;
  border-style: unset;
  box-sizing: unset;
  cursor: pointer;
}

.withdraw-deposit-container .stake-amount .deposit-button-container button:disabled {
  background-color: #555770;
  color: #8F90A6;
}

.withdraw-deposit-container .stake-amount .deposit-button-container .deposit-button .icon-button {
  margin-right:5px;
}

.withdraw-deposit-container .wallet-balance {
  margin-top: 12px;
  font-weight: 500;
  font-size: 12px;
}

.contract-info {
  padding-top: 20px;
}

.contract-info .set-pair-info,
.contract-info .view-contract {
  margin-top: 5px;
  text-align: left;
}

.contract-info .auto {
  position: absolute;
  bottom: 0;
}

.contract-info .auto .button {
  border: 2px solid transparent;
  background: linear-gradient(#1c1c28, #1c1c28) padding-box, linear-gradient(to right, #00cfde, #05a660) border-box;
  border-radius: 0.8em;
  cursor: pointer;
}

.contract-info .auto .button svg {
  margin-left: 15px;
  margin-top: 3px;
  animation: spin 3s linear infinite;

  &:hover {
    animation-play-state: paused;
  }
} 

.anticon.anticon-setting:hover {
  animation: spin 3s linear infinite;
}

.contract-info .auto .button:hover svg {
  animation-play-state: paused;
}

.contract-info .auto .button .text {
  margin-left: 3px;
  margin-right: 15px;
  font-weight: 500;
  font-size: 10px;
  margin-top: -50%;
}

@media (max-width: 576px) {
  .compounding-container .apy-container .percent {
    font-size: 28px;
  }
  .compounding-container .apy-container .text {
    font-size: 18px;
  }

  .compounding-container .total-container .number {
    text-align: end;
    font-size: 14px;
  }
  .compounding-container .total-container {
    text-align: unset;
  }
  .compounding-info .staked-container {
    text-align: unset;
  }
  .compounding-info .staked-container .number {
    text-align: end;
    font-size: 14px;
  }
  .compounding-container .apy-container {
    margin-left: unset;
  }
  .compounding-container .apy-container {
    text-align: end;
  }
  .compounding-container .detail-button a {
    position: initial;
  }
  .compounding-container .detail-button {
    text-align: center;
  }
  .feature-container .staked-container {
    display: none;
  }

  .withdraw-deposit-container {
    min-width: unset;
    margin-left: unset;
    padding-right: 2%;
  }

  .contract-info {
    display: none;
  }

  .withdraw-deposit-container .stake-amount .deposit-button-container {
    padding-left: unset;
    padding-right: unset;
  }
  .farmsPanel {
    margin-top: 20px !important;
  }
  .farmsContainer {
    margin-top: 10px;
  }
  .farmsPanel button {
    bottom: unset;
    margin-top: 10px;
    position: unset;
    max-width: 200px;
  }
}

@media (max-width: 768px) {
  .feature-container .staked-container {
    display: none;
  }

  .contract-info {
    display: none;
  }

  .withdraw-deposit-container {
    margin: auto;
    margin-left: -60px;
  }
}

@primary-color: #06c270;