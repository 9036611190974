.text-red {
    color: #FF3B3B;
}

.text-yellow-1 {
    color: #FFCC00;
}

.text-green {
    color: #05A660;
}

.text-green-light {
    color: #06c270;
    text-shadow: 8px 0px 80px #57eba1;
}

.text-gradient-4 {
    // color: linear-gradient(to bottom right, #00CFDE, #05A660);
    background: -webkit-linear-gradient(#00CFDE, #05A660);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}
  

.connect-button {
    background-color: #06C270; 
    color: white; 
    display: inline-block;
    border-radius: 2em; 
    padding-top: 10px; 
    padding-bottom: 10px;
    padding-right: 40px;
    padding-left: 40px; 
    cursor: pointer; 
    font-weight: 500;
}

.connect-button:hover {
    background-color: #ddaa00;
    -webkit-transition: background-color 0.5s linear;
    -ms-transition: background-color 0.5s linear;
    transition: background-color 0.5s linear;
}

.option-button {
    display: inline-block;
    position: relative;
    margin-left: 30px;
    cursor: pointer;
}

.option-button .option-menu {
    display: none;
}

.option-button:hover .option-menu {
    display: block;
    position: absolute;
    list-style-type: none;
    padding: 10px;
    font-size: 15px;
    left: -150px;
    width: 200px;;
    background-color: #1C1C28;
    box-shadow: 30px 12px 20px rgb(0, 0, 0 , 0.4);
}

.option-menu li:hover {
    color: #06c270;
    font-weight: bold;
}
@primary-color: #06c270;