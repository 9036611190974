@import '~antd/es/style/themes/dark.less';
@import "~antd/dist/antd.dark.less";

@font-face {
    font-family: "FF Oxide Solid";
    src: url("//db.onlinewebfonts.com/t/bc30adee89b44a44a5cfdff676aef0fe.eot");
    src: url("//db.onlinewebfonts.com/t/bc30adee89b44a44a5cfdff676aef0fe.eot?#iefix") format("embedded-opentype"),
         url("//db.onlinewebfonts.com/t/bc30adee89b44a44a5cfdff676aef0fe.woff2") format("woff2"),
         url("//db.onlinewebfonts.com/t/bc30adee89b44a44a5cfdff676aef0fe.woff") format("woff"),
         url("//db.onlinewebfonts.com/t/bc30adee89b44a44a5cfdff676aef0fe.ttf") format("truetype"),
         url("//db.onlinewebfonts.com/t/bc30adee89b44a44a5cfdff676aef0fe.svg#FF Oxide Solid") format("svg");
  }

@primary-color: #ff00a8;
@popover-background: #1C1C28;

.ant-popover-inner {
  background-color: #1C1C28;
}

.ant-select.ant-select-single.ant-select-show-arrow:hover {
  border-color: wheat!important;
}

.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.9);
}

.ant-modal-header {
  background: none;
  border-bottom: none;
}

.ant-modal-title {
  font-size: 1.5em;
}

.ant-modal-footer {
  border-top: none;
  text-align: center;
}

.ant-modal-content {
  background: none;
}

.ant-modal {
  position: absolute;
  top: 25%;
  text-align: center;
  width: 100%;
}

.ant-modal-header {
  font-size: 1.5em;
  padding: 2px;
}

.ant-modal-body {
  padding: 0px;
}

.border-modal {
  border: 2px solid #a29b9b;
  border-radius: 0.3em;
  margin: 20px;
}

.provider-button {
  display: inline-block;
  padding: 10px;
  text-align: center;
  margin-bottom: 8px;
  margin: 8px;
  width: 100px;
  height: 100px;
  border: none;
}

.provider-img {
  display: block; 
  margin: auto; 
  margin-bottom: 20px;
}

.ant-modal-footer .ant-btn {
  font-size: 1.2em;
  border: 1.5px solid #545252;
}

@media only screen and (max-width: 400px) {
  .ant-modal {
    top: 5%;
  }
}

// .ant-btn:hover, .ant-btn:focus {
//   color: #06c270;;
//   border-color: #06c270;
// }
@primary-color: #06c270;